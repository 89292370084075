
              @import "@/assets/css/variables.scss";
            
/* upload 开始 */
.el-upload {
  width: 100%;
}

.vUpload {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 30px;
}

.upload .labelTitle {
  margin-bottom: 10px;
}

.upload_demo .button_upload {
  height: 40px;
  font-size: 12px;
  line-height: 40px;
  padding: 0 15px;
  color: #ffffff;
  position: relative;
  @include rtl-sass-prop(left, right, 0);
  top: 0;
  border-radius: 23px;
  background-color: $primary-purple;
}

.el-upload__tip {
  font-size: 10px;
  font-weight: 200;
  line-height: 1.4;
  color: #000000;
  margin-top: 0;
  @include rtl-sass-value(text-align, left, right);
}

.el-upload-list__item-name {
  @include rtl-sass-prop(margin-right, margin-left, 40px);
  @include rtl-sass-prop(margin-left, margin-right, unset);
  @include rtl-sass-prop(padding-left, padding-right, 4px);
  @include rtl-sass-prop(padding-right, padding-left, unset);
}

.el-upload-list__item-status-label {
  @include rtl-sass-prop(right, left, 5px);
  @include rtl-sass-prop(left, right, unset);
}

/* upload 结束 */

/* 根据插件渲染出的dom类名，覆盖成设计图的样式 */
.el-main {
  padding: 0;
}

/* 下拉箭头去掉 */

.el-icon-arrow-up:before {
  display: none;
}

/* keep arrows for el filters */
.el-table__column-filter-trigger .el-icon-arrow-up:before {
  display: inline;
}

/* el filter style */
.el-table-filter {
  border: 0;
}

.el-table-filter__bottom {
  border: 0;
}

.el-table-filter__bottom .is-disabled:before {
  color: #cccfd4 !important;
}

.el-checkbox__label {
  color: #cccfd4;
}

.el-table-filter__bottom > button,
.el-table-filter__bottom > button:hover,
.el-table-filter__bottom .is-disabled {
  color: transparent !important;
}

.el-table-filter__bottom > button:nth-child(1):before {
  content: '\2003\2714';
  color: #cccfd4;
}

.el-table-filter__bottom > button:nth-child(2):before {
  content: '\2718';
  color: #cccfd4;
}

/* 公共樣式結束 */

/* 日期組件样式开始 */

.el-date-editor .el-input__inner {
  @include rtl-sass-prop(padding-left, padding-right, 40px !important);
}
/* 左侧日历图标 */

.el-input__prefix {
  @include rtl-sass-prop-sd(right, 5px);
  @include rtl-sass-prop-sd(left, unset);
}

.el-input__icon.dataIcon {
  width: 24px;
  height: 24px;
  margin-top: 10px;
  content: url(~@/assets/uploads/icons-tools-calendar@3x.png);
}

/* 修改日历下拉的样式 */

.el-picker-panel {
  box-shadow: 0 9px 35px 0 rgba(0, 0, 0, 0.13);
  background-color: #ffffff;
  border: unset;

  .popper__arrow {
    display: none;
  }
}

/* 下拉字体颜色 */
.el-picker-panel__icon-btn {
  color: $primary-purple;
}

.el-date-picker__header-label {
  color: $primary-purple;
  font-weight: bold;
}

.el-picker-panel__content tbody {
  color: rgb(119, 119, 119);
}

.el-date-picker__prev-btn {
  @include rtl-sass-prop-sd(float, right);
}

.el-date-picker__next-btn {
  @include rtl-sass-prop-sd(float, left);
}

.el-date-table th {
  color: $primary-purple;
  font-weight: bold;
  padding: 0;
}

.el-date-table td.current:not(.disabled) span {
  color: #ffffff;
  background: $primary-purple;
}

.el-date-table td.today span {
  color: $main-b-color;
  background: $primary-green;
}

.el-date-table td.next-month,
.el-date-table td.prev-month {
  color: #cccfd4;
}

.el-date-table td.available {
  color: $primary-purple;
}

.el-date-table td.disabled div {
  background-color: #e5e6e9;
  color: #666e7e;
}

.el-year-table td.disabled .cell {
  background-color: #e5e6e9;
  color: #666e7e;
}

.el-month-table td.disabled .cell {
  background-color: #e5e6e9;
  color: #666e7e;
}

/* 日期組件样式结束 */

/* 下拉菜单样式开始 */

.el-select {
  width: 100%;
}

.el-select .el-input__icon {
  display: none !important;
}

.el-select .el-input__inner {
  border: 0;
  color: $primary-purple;
  border-bottom: 1px solid $primary-purple;
  background-size: 24px 24px !important;
  @include rtl-sass-value(
    background,
    url(~@/assets/uploads/icon_caret_bottom.png) no-repeat right center transparent,
    url(~@/assets/uploads/icon_caret_bottom.png) no-repeat left center transparent
  );

  &::placeholder {
    opacity: 0;
  }

  &:hover {
    border-bottom: 1px solid $primary-purple;
  }

  &:focus {
    border-bottom: 1px solid $primary-purple !important;
  }
}

.el-select .is-focus .el-input__inner {
  background-size: 24px 24px !important;
  @include rtl-sass-value(
    background,
    url(~@/assets/uploads/icons-navigation-cheveron-up@3x.png) no-repeat right center transparent,
    url(~@/assets/uploads/icons-navigation-cheveron-up@3x.png) no-repeat left center transparent
  );
}

.showPlaceholder {
  .el-select .el-input__inner,
  .el-input .el-input__inner {
    &::placeholder {
      opacity: 1;
    }
  }

  .el-form-item__label {
    font-size: 14px;
    bottom: 45px;
  }
}

/* 多级下拉菜单联动开始 */
.el-cascader .el-input .el-input__inner {
  background-size: 24px 24px !important;
  @include rtl-sass-value(
    background,
    url(~@/assets/uploads/icon_caret_bottom.png) no-repeat right center transparent,
    url(~@/assets/uploads/icon_caret_bottom.png) no-repeat left center transparent
  );
}

.el-cascader.is-opened .el-input .el-input__inner {
  background-size: 24px 24px !important;
  @include rtl-sass-value(
    background,
    url(~@/assets/uploads/icons-navigation-cheveron-up@3x.png) no-repeat right center transparent,
    url(~@/assets/uploads/icons-navigation-cheveron-up@3x.png) no-repeat left center transparent
  );
}

.el-cascader-menu__item--extensible:after {
  @include rtl-sass-value(left, unset, 15px);
  @include rtl-sass-value(right, 15px, unset);
  @include rtl-sass-prop-sd(transform, rotate(180deg));
}

.el-cascader__label {
  @include rtl-sass-prop-sd(text-align, right);
}

.el-cascader .el-icon-arrow-down:before {
  display: none;
}

.el-cascader-menus {
  border: none;
}

/* 修改小箭头 */
.el-cascader-menus .popper__arrow {
  display: none;
}

/* 修改下拉背景色 */
.el-cascader-menus .el-cascader-menu {
  border: none;
  max-height: 300px;
  height: 100%;
}

/* 修改下拉字体颜色 */
.el-cascader-menu__item {
  color: $primary-purple;
  background: none;
  -webkit-background-clip: text;

  &:hover {
    color: $primary-purple;
  }
}

/* 选中的样式 */
.el-cascader-menu__item.is-active {
  span {
    color: $primary-purple;
  }
}
/* 多级下拉菜单联动结束 */

/* settings表格样式开始 */

/* table 表格样式开始 */

.el-table,
.el-table__expanded-cell {
  background-color: transparent;
  border: solid 3px $primary-purple;
  border-radius: $container-radius;
  border-width: 0 3px 3px 3px;
}

.el-table thead {
  background: $primary-purple;
}
/* tr和th的背景色 */
.el-table th,
.el-table tr {
  padding: 0;
  background: transparent !important;
}

.el-table td,
.el-table th {
  text-align: center;
}

.el-table .el-table__body tr {
  box-shadow: 0px 24px 3px -24px #b1b1b1;
}

.el-table td {
  padding: 0;
  border-bottom: unset;
}

.el-table th {
  color: #ffffff;
}

.el-table .el-table__cell {
  padding: 0px;
}

.el-table th .cell {
  padding: 25px 20px;
  word-break: break-word;
  text-align: center;
}

.el-table td .cell {
  padding: 25px 12px;
  word-break: break-word;
  color: $primary-purple;
  text-align: center;
}

.el-table__empty-text {
  color: $primary-purple;
}

/* table 表格样式结束 */

/* input框开始 */

.el-input__inner {
  height: auto;
  line-height: normal;
  padding: 12px 0;
  border: 0;
  background: none;
  color: $primary-purple;
  border-bottom: 1px solid #cccfd4;
  border-radius: 0;
  font-size: 20px;
  @include rtl-sass-prop-sd(padding-left, 30px);
  @include rtl-sass-prop-sd(padding-right, 0);
  &:hover {
    border-bottom: 1px solid $primary-purple;
  }

  &:focus {
    border-color: $primary-purple !important;
  }
}

.el-input.is-disabled .el-input__inner {
  background-color: unset;
  border: unset;
  border-bottom: 1px solid $primary-purple;
  color: $primary-purple;
}

/* input框结束 */

/* radio 样式开始 */

/* 每一个label */

.el-radio {
  font-size: 14px;
  font-weight: normal;
  color: #bbd1ed;
}

/* 默认radio的背景颜色 */

.el-radio__inner {
  width: 16px;
  height: 16px;
  border: 0;
  background-color: #35485e;
}

/* 圆心内的样式 */

.el-radio__inner::after {
  width: 8px;
  height: 8px;
  background-color: #bbd1ed;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.26);
}

/* 选中radio的样式 */

.el-radio__input.is-checked .el-radio__inner {
  border-color: #35485e;
  background-color: #35485e;
}

/* 选中的文字颜色 */

.el-radio__input.is-checked + .el-radio__label {
  color: #bbd1ed;
}

/* radio 样式结束 */

/* dialog对话框显示开始 */

/* body上面的样式 */
.el-popup-parent--hidden {
  padding-right: 0 !important;
}

.el-dialog__wrapper {
  padding: 0px 20px;

  .el-dialog {
    width: 100%;
    max-width: 673px;
    margin: 0 auto;
    box-shadow: 0 9px 35px 0 rgba(0, 0, 0, 0.13);
    top: 50%;
    transform: translateY(-50%);
  }

  .el-dialog__header {
    text-align: right;

    .closeImg {
      cursor: pointer;
    }

    // hide default close icon
    .el-dialog__headerbtn {
      display: none;
    }
  }

  .el-dialog__body {
    padding: 0 48px 40px;
  }
}

/* dislog对话框关闭结束 */

/* 分页开始 */
.el-pagination {
  font-size: 16px;
  font-weight: 200;
  line-height: 1.56;
}

/* 按钮默认背景色 */
.el-pagination button,
.el-pagination.is-background .el-pager li {
  height: 40px;
  line-height: 40px;
  background-color: unset !important;
}

.el-pagination.is-background .el-pager li.active {
  color: $primary-purple !important;
  border-bottom: solid 3px $primary-purple;
}

/* 分页结束 */

/* form表单开始 */

.el-form-item {
  position: relative;
  margin-bottom: 45px;
}

.el-form-item__label {
  position: absolute;
  @include rtl-sass-prop(left, right, 0);
  bottom: 14px;
  padding: 0;
  transition: 0.2s;
  font-size: 16px;
  color: $primary-purple;
  @include rtl-sass-value(text-align, left, right);
  line-height: unset;
}

.el-form-item.is-error .el-form-item__label,
.el-form-item.is-error .el-checkbox__label {
  color: #e91d2c !important;
}

.el-form-item.is-error .el-input__inner {
  border-bottom: 1px solid #e91d2c !important;
}

.el-form-item__error {
  color: #e91d2c;
  font-size: 14px;
}

.el-form-item.is-success .el-input__inner,
.el-form-item.is-success .el-input__inner:focus,
.el-form-item.is-success .el-textarea__inner,
.el-form-item.is-success .el-textarea__inner:focus {
  border-color: #cccfd4;
}

.labelTitle {
  line-height: 14px;

  .required_icon {
    color: #e91d2c;
    margin-right: 4px;
  }

  label {
    color: $primary-purple;
  }
}

/* 修改滚动条样式开始 */
/*定义滚动条宽高  背景色*/
.el-table__body-wrapper::-webkit-scrollbar,
.el-cascader-menu::-webkit-scrollbar,
.menu .main_box::-webkit-scrollbar,
.el-pagination_box::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

/*定义滚动条轨道 内阴影+圆角*/

.el-table__body-wrapper::-webkit-scrollbar-track,
.el-cascader-menu::-webkit-scrollbar-track,
.menu .main_box::-webkit-scrollbar-track,
.el-pagination_box::-webkit-scrollbar-track {
  border-radius: 8px;
  background: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/

.el-table__body-wrapper::-webkit-scrollbar-thumb,
.el-cascader-menu::-webkit-scrollbar-thumb,
.menu .main_box::-webkit-scrollbar-thumb,
.el-pagination_box::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $primary-purple;
}

.el-table__body-wrapper::-webkit-scrollbar-thumb:hover,
.el-cascader-menu::-webkit-scrollbar-thumb:hover,
.menu .main_box::-webkit-scrollbar-thumb:hover,
.el-pagination_box::-webkit-scrollbar-thumb:hover {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* // 边角 右下角 */
.el-table__body-wrapper::-webkit-scrollbar-corner,
.el-cascader-menu::-webkit-scrollbar-corner,
.menu .main_box::-webkit-scrollbar-corner,
.el-pagination_box::-webkit-scrollbar-corner {
  // background-color: #181e26;
}

.el-table__body-wrapper,
.el-cascader-menu,
.menu .main_box,
.el-pagination_box {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* 修改手機點擊兩次 */
.el-scrollbar {
  > .el-scrollbar__bar {
    opacity: 1;
  }
}
/* 修改滚动条样式结束 */

/* 折叠面板开始 */
.el-collapse {
  border: 0;
}

.el-collapse-item__content {
  padding-bottom: 0;
}

.el-collapse-item__header,
.el-collapse-item__wrap {
  border: 0;
}

.el-carousel__mask,
.el-cascader-menu,
.el-cascader-menu__item.is-disabled:hover,
.el-collapse-item__header,
.el-collapse-item__wrap,
.el-cascader-menu__item.el-cascader-menu__item--extensible.is-active {
  background: transparent;
}

/* 折叠面板结束 */
/* Dropdown 下拉菜单开始 */

.el-dropdown-menu,
.el-select-dropdown {
  border: 0 !important;
  padding: 0 !important;
  box-shadow: 0 9px 35px 0 rgba(0, 0, 0, 0.13);
  background-color: #ffffff;

  /* 小箭头 */
  .popper__arrow {
    display: none;
  }
}

.el-dropdown-menu__item:not(.is-disabled):hover,
.el-select-dropdown__item.selected,
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #ffffff;
  color: $primary-purple;
}

.el-dropdown-menu__item:hover span,
.el-dropdown-menu__item.active span {
  color: $primary-purple !important;
}

/* Dropdown 下拉菜单结束 */

/* checkbox 开始 */
.el-checkbox {
  white-space: pre-wrap;
  width: 100%;

  .el-checkbox__input {
    .el-checkbox__inner {
      &:hover {
        border-color: #cccfd4;
      }
    }

    &.is-checked {
      .el-checkbox__inner {
        background-color: #ffffff;
        border-color: #cccfd4;

        &::after {
          border-color: $primary-purple;
        }
      }

      + .el-checkbox__label {
        color: $primary-purple;
      }
    }

    &.is-focus {
      .el-checkbox__inner {
        border-color: #cccfd4;
      }
    }
  }

  .el-checkbox__label {
    width: calc(100% - 36px);
    @include rtl-sass-prop(padding-left, padding-right, 18px);
    color: $primary-purple;
  }
}

/* checkbox 结束 */

/* begin: do not display increase, decrease button */
/* 谷歌 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* firfox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* end: do not display increase, decrease button */

/* form表单结束 */

/* date picker start*/

.el-picker-panel__sidebar {
  display: none;
}

/* date picker end */

.el-message {
  width: 80%;
  min-width: 80%;
  background-color: #ffffff;
  border: unset;
  box-shadow: 0 9px 35px 0 rgba(0, 0, 0, 0.13);
  padding: 32px 24px;
  font-size: 16px;
  font-weight: 200;
  line-height: 1.56;
  color: $primary-purple;

  .el-icon-warning:before,
  .el-icon-info:before,
  .el-icon-error:before {
    content: url(~@/assets/uploads/icons-error.png);
  }
}

/* form表单校验结束 */

.el-scrollbar__wrap {
  scrollbar-width: none;
  overflow: scroll;
}

.el-message__icon {
  @include rtl-sass-prop(margin-right, margin-left, 10px);
}

.el-scrollbar__wrap {
  @include rtl-sass-prop-dual(margin-right, 0, margin-right, 0 !important);
  @include rtl-sass-prop-dual(margin-left, 0, margin-left, -6px !important);
}

.el-icon-arrow-left {
  @include rtl-sass-prop-sd(transform, rotate(180deg));
}

.el-icon-arrow-right {
  @include rtl-sass-prop-sd(transform, rotate(180deg));
}

.el-icon-d-arrow-left {
  @include rtl-sass-prop-sd(transform, rotate(180deg));
}

.el-icon-d-arrow-right {
  @include rtl-sass-prop-sd(transform, rotate(180deg));
}

@keyframes Rotate {
  form {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

@media (max-width: 768px) {
  .el-dialog__wrapper {
    .el-dialog {
      top: 50px;
      transform: none;
    }
  }

  .vUpload {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    row-gap: 15px;
  }

  .upload_demo .button_upload {
    position: unset;
  }

  .el-upload__tip {
    text-align: center;
  }
}

@media (max-width: 550px) {
  .el-input__inner {
    font-size: 16px;
  }

  .el-form-item__error {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  /* 下拉框 */
  .el-cascader .el-input .el-input__inner,
  .el-select .el-input .el-input__inner {
    @include rtl-sass-value(background-position, 95% center, 5% center);
  }
}

@media (max-width: 414px) {
  .el-date-editor .el-input__inner {
    padding-left: 30px !important;
    background-position: 95% center;
  }

  .el-picker-panel .el-picker-panel__body .el-date-picker__header {
    margin: 12px 20px;
  }
}
