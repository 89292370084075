
              @import "@/assets/css/variables.scss";
            

.l_select {
  position: relative;
  position: sticky;
  z-index: 101;

  input {
    position: sticky;
    z-index: 101;
  }

  &.active,
  &[value]:not([value='']) {
    /deep/ label {
      font-size: 14px;
      bottom: 45px;
    }
  }
}
