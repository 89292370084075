
              @import "@/assets/css/variables.scss";
            
.purple_button,
.green_button {
  height: 46px;
  border-radius: $button-radius !important;
  background-color: $primary-purple !important;
  color: #fff;
  margin-right: 10px;

  &:focus {
    background-color: $primary-purple;
    color: #fff;
  }

  &:hover,
  &:active {
    border: unset;
    background-color: $primary-purple !important;
    color: $white;
  }
}

.green_button {
  background-color: $primary-green !important;

  &:focus {
    background-color: $primary-green;
    color: #fff;
  }

  &:hover,
  &:active {
    border: unset;
    background-color: $primary-green !important;
    color: $white;
  }
}

.white_button {
  height: 46px;
  border-radius: $button-radius !important;
  border: solid 1px $primary-purple !important;
  background-color: unset;
  color: $primary-purple;
  margin-right: 10px;

  &:focus {
    border: solid 1px $primary-purple;
    background-color: unset;
    color: $primary-purple;
  }

  &:hover,
  &:active {
    border: unset;
    background-color: $primary-purple;
    color: $white;
  }
}

.li_button {
  display: inline-block;
  text-align: center;
  color: $primary-purple;
  min-width: 120px;
  min-height: 46px;
  height: 100%;
  border-radius: 23px;
  line-height: 1.5;
  border: solid 1px $primary-purple;
  cursor: pointer;
  padding: 12px 20px;

  &:first-child {
    margin-right: 10px;
  }

  &.active {
    color: #ffffff;
    border: unset;
    background-color: $primary-purple;
  }

  &:focus {
    border: unset;
    background-color: #00f0ff;
    color: $primary-purple;
  }
}

/* 分页开始 */
.page_box {
  margin-bottom: 50px;
  text-align: right;
}

.page_box .el-pagination_box {
  float: right;
}
/* 分页结束 */

@media (max-width: 768px) {
  .page_box {
    text-align: center;
  }

  .page_box .el-pagination_box {
    float: none;
    display: block;
  }
}

@media (max-width: 550px) {
  .page_box .el-pagination_box {
    overflow-x: scroll;
    padding-bottom: 15px;
  }

  .page_box .el-pagination_box /deep/ .el-pagination {
    width: 495px;
  }

  .purple_button,
  .white_button {
    margin-bottom: 10px;
  }

  .el-button + .el-button {
    margin-left: 0;
  }

  .table_item_box {
    .title {
      font-size: 24px;
    }
  }
}
